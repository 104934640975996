import * as React from "react";

import "assets/scss/material-kit-pro-react.scss?v=1.9.0";
import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle";
import "typeface-roboto";
import "typeface-roboto-slab";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

import MyHeader from "components/Header/MyHeader";
import MyFooter from "components/Footer/MyFooter";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Parallax from "components/Parallax/Parallax";

// Sections for this page
import SectionContacts from "./HomePage/Sections/SectionContacts";

const useStyles = makeStyles(aboutUsStyle);

// markup
const ETSPage = ({ ...rest }) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const classes = useStyles();

  return (
    <div>
      <MyHeader {...rest} />
      <Parallax image={require("assets/img/on_demand.jpg")} filter="dark" small>
        <div className={classes.container} />
      </Parallax>
      <div className={classNames(classes.main)}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}></GridItem>
            <GridItem
              xs={12}
              sm={7}
              md={7}
              className={`${classes.mlAuto} ${classes.mrAuto}`}
            >
              <h2 className={classes.title} style={{ color: "black" }}>
                What is an Element Tracking System (ETS)?
              </h2>
              <p>
                An Element Tracking System (ETS) is a process-oriented software
                that traces out everything involved in your work, including the
                project, the process, and the people.
                <ul>
                  <li>
                    The project involves assets such as video files, scripts,
                    audio, fonts, etc.
                  </li>
                  <li>
                    The process includes elements such as deadlines, workflow,
                    project management, and communication.
                  </li>
                  <li>
                    The people includes roles, load balancing, task assignments,
                    and handoffs.
                  </li>
                </ul>
              </p>
              <p>
                An ETS parallels a number of software types, such as Media Asset
                Managers (MAMs), Digital Asset Manager (DAMs), Enterprise
                Resource Planning (ERPs), and Supply Chain Planning &
                Manufacturing, but in a more complete manner designed
                specifically for the entertainment industry. Further, an ETS
                links seamlessly with other kinds of software and data resources
                to maximize timely decisions and make simple the complex.
              </p>
              <p>
                An ETS is therefore an asset repository, a project database, an
                automated project manager, a scheduling tool, and a
                communication hub that links to other resources in one central
                app for teams of creative professionals. A proper ETS handles
                all the details in an intuitive framework. Such a system must
                foster creative productivity in an intelligent, powerful, and
                precise way, integrating team functions, project flow, and asset
                management.
              </p>
              <p>
                All the tasks, all the data, all the steps, all the roles, who
                does what, when you need it, wherever you need it, in a
                gratifying, simple to use, eye pleasing and easily accessible
                way. This is the equivalent of a master craftsperson’s workbench
                for the promo industry.
              </p>
              <p>
                An Element Tracking System is the core that keeps you creating.
              </p>
            </GridItem>
          </GridContainer>
        </div>
        <br />
        <SectionContacts />
      </div>
      <MyFooter {...rest} />
    </div>
  );
};

export default ETSPage;
